import { cookiesDataByName } from '_libs/common/cookie-manager/cookies-data'
import useCookie from '_libs/common/cookie-manager/hooks/useCookie'

import { ColorTheme } from '../constants'

const useIsDarkModeEnabledFromCookies = () => {
  const cookies = useCookie()

  return cookies.get(cookiesDataByName.color_theme) === ColorTheme.Dark
}

export default useIsDarkModeEnabledFromCookies
